import React from "react"

const Testimonial = () => {
  return (
    <section className="section-testimonial">
      <div className="container">
        <div
          id="testimonialCarousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <h2>Perché l'ozono?</h2>
              <p>
                L’università di Nara (Giappone) è stata la prima al mondo a dare
                una risposta scientifica a questa domanda, confermando
                l'efficacia dell’ozono contro la SARS-CoV-2 e tutti i virus
                derivati da essa. È dimostrato che anche il COVID-19 viene
                inattivato tramite la sanificazione ad ozono.
              </p>
              <p className="text-gray">Università di Nara (Giappone)</p>
            </div>
            <div className="carousel-item">
              <h2>Perché l'ozono?</h2>
              <p>
                "Con pubblicazione n.25/2020 l'ozono come disinfettante e
                sanitizzante microbiologico anche del virus."
              </p>
              <p className="text-gray">Istituto Superiore della Sanità</p>
            </div>
            <div className="carousel-item">
              <h2>Perché l'ozono?</h2>
              <p>
                "Ozono come presidio naturale per la sterilizzazione di ambienti
                contaminati da batteri, virus, spore, muffe ed acari."
              </p>
              <p className="text-gray">Ministero della Salute</p>
            </div>
            <div className="carousel-item">
              <h2>Perché l'ozono?</h2>
              <p>
                "Con concentrazioni di ozono di 0,1-0,2 mg / L min, si ottiene
                l'inattivazione di tutti i tipi di virus."
              </p>
              <p className="text-gray">Organizzazione mondiale della Sanità</p>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#testimonialCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#testimonialCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
