import React from "react"

const Faq = () => {
  return (
    <section className="section-faq">
      <div className="container">
        <h2>FAQ</h2>
        <div className="accordion" id="faqAccordion">
          <div className="card">
            <div className="card-header" id="faqHead1">
              <button
                className="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#faq1"
                aria-expanded="true"
                aria-controls="faq1"
              >
                L’aria ozonizzata è più salubre?
              </button>
            </div>

            <div
              id="faq1"
              className="collapse show"
              aria-labelledby="faqHead1"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Sì, perché l’utilizzo dell’ozono serve per eliminare batteri,
                funghi, insetti e virus presenti negli ambienti.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="faqHead2">
              <button
                className="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq2"
                aria-expanded="false"
                aria-controls="faq2"
              >
                Come è garantito il funzionamento in sicurezza di Ozy?
              </button>
            </div>
            <div
              id="faq2"
              className="collapse"
              aria-labelledby="faqHead2"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Il funzionamento di OZY avviene da remoto mediante la App.
                <br/>
                OZY non funziona in presenza di persone o animali: è provvisto
                di sensori di presenza persona (PIR) che interrompono il
                funzionamento o impediscono l’avvio in caso di rilevazione.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="faqHead3">
              <button
                className="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq3"
                aria-expanded="false"
                aria-controls="faq3"
              >
                È prevista una manutenzione?
              </button>
            </div>
            <div
              id="faq3"
              className="collapse"
              aria-labelledby="faqHead3"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Sì, è necessaria la sostituzione del filtro una volta all’anno e
                la verifica della calibrazione del sensore di ozono. La gestione
                viene notificata dall’App.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="faqHead4">
              <button
                className="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq4"
                aria-expanded="false"
                aria-controls="faq4"
              >
                Al termine della sanificazione, posso accedere immediatamente
                nel locale?
              </button>
            </div>
            <div
              id="faq4"
              className="collapse"
              aria-labelledby="faqHead4"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                Sì, il ciclo di sanificazione si conclude con la rimozione
                totale dell’ozono (e non solo). L’App notifica direttamente
                quando il ciclo di sanificazione è concluso e il locale
                accessibile.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="faqHead5">
              <button
                className="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#faq4"
                aria-expanded="false"
                aria-controls="faq4"
              >
                ELENCO DELLE NORME A CUI O3ZY SA FA RIFERIMENTO
              </button>
            </div>
            <div
              id="faq4"
              className="collapse"
              aria-labelledby="faqHead4"
              data-parent="#faqAccordion"
            >
              <div className="card-body">
                I prodotti OZY sono stati testati da un laboratorio accreditato secondo la norma di riferimento SN
                17025:2018.<br/><br/>
                <strong>DICHIARAZIONE CE DI CONFORMITÀ DEL COSTRUTTORE:</strong>
                <ul className="list-unstyled">
                  <li>2014/35/EU - Low Voltage Directive</li>
                  <li>2014/30/EU - Electromagnetic Compatibility Directive</li>
                  <li>2011/65/EU - RoHS Directive</li>
                </ul>
                <strong>2014/35/EU</strong>
                <ul className="list-unstyled">
                  <li>EN 60335-1:12, A11:14, A13:17</li>
                  <li>EN 60335-2-65:03, A1:08, A11:10</li>
                  <li>EN 62233:08</li>
                </ul>
                <strong>2014/30/EU</strong>
                <ul className="list-unstyled">
                  <li>EN 55014-1:17</li>
                  <li>EN 55014-2:15</li>
                  <li>EN 61000-3-2:19</li>
                  <li>EN 61000-3-3:13, A:19</li>
                  <li>ETSI EN 301 489-1 V2.2.3</li>
                  <li>ETSI EN 301 489-17 V3.1.1</li>
                  <li>ETSI EN 300 328 V2.1.1</li>
                </ul>
                <strong>2011/65/EU</strong>
                <ul className="list-unstyled">
                  <li>EN 62321</li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
