import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import logo from "../images/logo.svg"
import iconVirus from "../images/icon-novirus.svg"
import iconAir from "../images/icon-air.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonial from "../components/testimonial"
import Faq from "../components/faq"
import Form from "../components/form"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO image="/images/P1111593.jpg" title={'O3zy'}/>

    <section className="section-intro">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 text-white">
            <a className="navbar-brand" href="/" rel="home" itemProp="url">
              <img
                width="125"
                height="76"
                src={logo}
                alt="Logo O3ZY"
                itemProp="logo"
                style={{ marginTop: `24px`, marginBottom: `80px` }}
              />
            </a>
            <h1>
              OZY: sanifica
              <br/>
              gli ambienti
              <br/>
              con l'ozono
            </h1>
            <p>
              OZY è un sanificatore con processo di erogazione e misurazione
              dell'ozono brevettato. Efficace contro virus e patogeni.
            </p>
            <p className="font-weight-bold text-uppercase text-gray small">
              Swiss made: garanzia di sicurezza e qualità
            </p>
          </div>

          <div className="col-12 col-lg-5 offset-lg-2" id="form">
            <h2 className="text-uppercase text-primary">
              Contatta un nostro esperto
            </h2>
            <p>
              Compila il form e sarai contattato da uno dei nostri consulenti.
            </p>
            <Form/>
          </div>
        </div>
        <div className="scroll-cta">
          <span>SCROLL</span>
          <div className="scroll-cta-line"></div>
        </div>
      </div>
    </section>

    <section className="section-bg">
      <Img
        title="Sfondo della sezione"
        alt="Ozonizzatore in una sala di ristorante"
        style={{ position: `absolute` }}
        fluid={data.bgImage.childImageSharp.fluid}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h2>
              Sicurezza ed efficacia: una soluzione a misura per le imprese
            </h2>
            <p>
              La sanificazione con l'ozono è efficace per l'eliminazione dei
              virus (come il COVID-19) solo se la concentrazione in ambiente è
              stabile ad almeno 4,2 ppm per almeno 20 minuti. Ecco perché OZY,
              con il suo sistema di erogazione e misura brevettato, è l'unico
              dispositivo in grado di sanificare le superfici (banchi di lavoro,
              attrezzature, indumenti) e che riporta l'ambiente in sicurezza
              filtrando l'aria e ripulendola dall'ozono dopo il ciclo di
              sanificazione.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section-double-column">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="col-inner">
              <Img
                title="OZY foto ambientata"
                alt="Ozonizzatore in una stanza"
                fluid={data.ozyPhoto2.childImageSharp.fluid}
              />
              <h3>OZY sanifica gli ambienti in modo efficace ed ecologico</h3>
              <p className="mb-5">
                È noto che l’Ozono, grazie alle sue proprietà fortemente
                ossidanti, attacca gli agenti patogeni o nocivi, compresi i
                virus, eliminandoli dall’ambiente trattato.
              </p>
              <a href="#form" className="btn btn-secondary mr-auto">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="col-inner">
              <Img
                title="Grafico concentrazioni"
                alt="Grafico concentrazioni"
                fluid={data.graphImage.childImageSharp.fluid}
              />
              <h3>
                Verifica delle concentrazioni di ozono e della qualità dell'aria
              </h3>
              <p>
                Questo grafico è un esempio di un ciclo* con parametri impostati
                per l’inattivazione di Virus:
              </p>
              <ul className="mb-5">
                <li>4,2 ppm (parti per milione)</li>
                <li>20 minuti mantenimento (durata del ciclo)</li>
              </ul>
              <a href="#form" className="btn btn-secondary mr-auto">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-gray">
            *Al termine di ogni ciclo tutti i valori sono scaricabili in pdf.
          </div>
        </div>
      </div>
    </section>

    <section className="section-theysay">
      <div className="container">
        <h2>Dicono di noi</h2>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/pjCuIUmSvC8?controls=0"
            title="Dicono di noi"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>

    <section className="section-why">
      <div className="container">
        <div className="text-center main-title">
          <h2>Perché OZY?</h2>
          <p>È l'unico ozonizzatore</p>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="box">
              <h3>Certificazioni</h3>
              <p>
                È l'unico sistema brevettato e certificato per la sanificazione
                degli ambienti.
              </p>
              <a href="#form" className="btn btn-light">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <h3>Qualità</h3>
              <p>
                Munito di sensori certificati, e collaudato ad uno ad uno prima
                della consegna.
              </p>
              <a href="#form" className="btn btn-light">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="box">
              <h3>Smart</h3>
              <p>
                Controllabile da remoto tramite APP, con l'emissione di un
                report di sanificazione per ogni ciclo.
              </p>
              <a href="#form" className="btn btn-light">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-how">
      <Img
        title="Sfondo della sezione"
        alt="Foto close-up di ventilatore"
        style={{ position: `absolute` }}
        fluid={data.bgFan.childImageSharp.fluid}
      />
      <div className="container">
        <div className="text-center text-white main-title">
          <h2>COME FUNZIONA OZY?</h2>
          <p>Scopri come sanifica e depura gli ambienti</p>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="box">
              <img
                src={iconVirus}
                width="163"
                height="162"
                alt="Icona novirus"
              />
              <p className="text-gray">Contro virus e patogeni</p>
              <h3>Sanificazione delle superfici</h3>
              <p>
                <strong>
                  Rileva la qualità dell’aria presente nell’ambiente
                </strong>
              </p>
              <p>
                OZY genera ozono fino al livello prestabilito e Tramite Sanity
                Filter cattura e catalizza particelle e molecole inquinanti. Il
                livello viene mantenuto per il tempo necessario ad ottenere il
                risultato. Al termine del flusso di ozono, inizia il ciclo di
                filtrazione e pulizia dell’aria fino al ripristino delle
                condizioni abitative ottimali. I valori della qualità dell'aria
                a fine ciclo sono scaricabili e archiviabili.
              </p>
              <a href="#contactForm" className="btn btn-secondary mr-auto">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="box">
              <img src={iconAir} width="163" height="162" alt="Icona aria"/>
              <p className="text-gray">Mantenimento della qualità dell’aria</p>
              <h3>Depurazione dell’aria</h3>
              <p>
                <strong>I sensori sono sempre attivi</strong>
              </p>
              <p>
                OZY rileva automaticamente l’eventuale eccesso di ozono e degli
                inquinanti nell’aria e tramite Sanity Filter cattura e catalizza
                particelle e molecole inquinanti. L’aria viene sanificata così
                da mantenerne stabile e salubre la qualità.
              </p>
              <a href="#contactForm" className="btn btn-secondary mr-auto">
                SCOPRI DI PIÙ
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Testimonial/>

    <Faq/>

  </Layout>
)

export default IndexPage

export const query = graphql`
    query {
        bgImage: file(relativePath: { eq: "photo1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bgFan: file(relativePath: { eq: "fan.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ozyPhoto2: file(relativePath: { eq: "photo2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 728) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphImage: file(relativePath: { eq: "photo3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 728) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
