import React from "react"

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = { typeOption: "" }
    this.handleTypeChange = this.handleTypeChange.bind(this)
  }

  handleTypeChange(event) {
    this.setState({ typeOption: event.target.value })
  }

  render() {
    return (
      <form id="contactForm" action="api/contact.php" method="post">
        <div className="form-group">
          <label className="sr-only" htmlFor="cfName">
            Nome e cognome
          </label>
          <input
            type="text"
            className="form-control"
            id="cfName"
            name="cfName"
            placeholder="Nome e cognome*"
            required
          />
        </div>

        <div className="form-group">
          <select className="custom-select" name="cfType"
                  value={this.state.typeOption}
                  onChange={this.handleTypeChange}
                  required>
            <option value="" disabled hidden>Tipologia attività</option>
            <option value="Impresa">Impresa</option>
            <option value="Associazione">Associazione</option>
            <option value="Struttura sanitaria">Struttura sanitaria</option>
            <option value="HoReCa">HoReCa</option>
            <option value="Pubblica Amministrazione">Pubblica Amministrazione</option>
            <option value="Ente sportivo">Ente sportivo</option>
            <option value="Attività Commerciali">Attività Commerciali</option>
            <option value="Studio Dentistico">Studio Dentistico</option>
            <option value="altro">Altro (specifica)</option>
          </select>
          {this.state.typeOption === "altro" &&
          <input
            type="text"
            className="form-control"
            id="cfTypeOther"
            name="cfTypeOther"
            placeholder="specifica la tipologia... *"
            required
          />
          }
        </div>

        <div className="form-group">
          <label className="sr-only" htmlFor="cfEmail">
            E-mail
          </label>
          <input
            type="email"
            className="form-control"
            id="cfEmail"
            name="cfEmail"
            placeholder="E-mail*"
            required
          />
        </div>

        <div className="form-group">
          <label className="sr-only" htmlFor="cfPhone">
            Telefono
          </label>
          <input
            type="text"
            className="form-control"
            id="cfPhone"
            name="cfPhone"
            placeholder="Telefono*"
            required
          />
        </div>

        <div className="form-group">
          <label className="sr-only" htmlFor="cfMessage">
            Messaggio
          </label>
          <textarea
            className="form-control"
            id="cfMessage"
            name="cfMessage"
            rows="3"
            placeholder="Messaggio"
          ></textarea>
        </div>

        <div className="acceptThis">
          <div className="custom-control custom-checkbox acceptance">
            <input
              type="checkbox"
              className="custom-control-input"
              id="cfPrivacy"
              name="cfPrivacy"
              required
            />
            <label className="custom-control-label" htmlFor="cfPrivacy">
              Dichiaro di aver preso visione delle <a
              href="https://www.iubenda.com/privacy-policy/84231219"
              target="_blank" rel="noreferrer"
            >Privacy Policy</a> ai sensi del Regolamento (UE) 2016/679. I dati personali forniti saranno
              utilizzati per adempiere alla mia richiesta.
              *
            </label>
          </div>

          <div className="custom-control custom-checkbox acceptance">
            <input
              type="checkbox"
              className="custom-control-input"
              id="cfMarketing"
              name="cfMarketing"
            />
            <label className="custom-control-label" htmlFor="cfMarketing">Autorizzo, inoltre, O3ZY SA a trattare i
              miei dati personali per attività di marketing.</label>
          </div>
        </div>

        <div className="form-group">
          <button type="submit" className="btn btn-secondary">
            Invia
          </button>
        </div>
      </form>
    )
  }
}

